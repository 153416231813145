import React from 'react'
import cx from 'classnames'
import branding from '../images/noun_branding_1885335.svg'
import processing from '../images/noun_The Process_1885341.svg'
import modeling from '../images/noun_3d modeling_1885342.svg'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import carphone from '../images/Dixons_Carphone_logo_grey.png'
import bjss from '../images/BJSS_grey.png'
import plusnet from '../images/Plusnet_logo_grey.png'
import sknnt from '../images/sknt-logo_grey.png'
import HomePageContentLayout from '../components/home-page-content-layout'

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    'py-2 px-4 bg-red-700 hover:bg-red-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1',
    className
  )
  return (
    <button className={className} {...props}>
      {children}
    </button>
  )
}

const Service = ({ title, url, children }) => {
  return (
    <div className="w-full md:w-1/2 p-4 service border-t">
      <Link to={url} className="text-3xl text-red-700 hover:text-indogo-600 hover:underline">
        {title}
      </Link>
      <div className="markdown-body excerpt" dangerouslySetInnerHTML={{ __html: children }}></div>
      <Link to={url} className="text-red-700 hover:text-indogo-600 hover:underline">
        Find out more about this service
      </Link>
    </div>
  )
}

function Index({ data }) {
  const services = data.services.edges

  return (
    <Layout headerClass="relative bg-white">
      <SEO title="Home" />
      <HomePageContentLayout>
        <div className="container w-full md:w-2/3 lg:w-1/2 xl:1/4 md:mt-8 md:ml-10 self-start">
          <div
            className="bg-white rounded flex flex-col sm:flex-row items-center md:items-start text-sm p-4"
            style={{
              boxShadow: '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)',
            }}
          >
            <div className="flex-1">
              <p>
                <strong>Phone: </strong>
                +44 (0)7815 166434
              </p>
              <p>
                <strong>Email: </strong>
                clive@distortedthinking.agency
              </p>
            </div>
            <div className="flex-initial mt-6 sm:mt-0">
              <Link to="/contact">
                <StyledButton>Contact</StyledButton>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-10 md:mt-16">
          <h3 className="text-2xl text-center">
            With nearly 20 year industry expertise working with some of the UK's best known and loved household names
            you can be sure that your brand is in the best hands
          </h3>
        </div>

        <div className="flex flex-col mt-10 md:mt-16">
          <p className="mb-2 text-5xl text-gray-800 self-center">Our Services</p>

          <div className="flex flex-wrap border-b justify-evenly">
            {services.map(({ node }) => (
              <Service title={node.frontmatter.title} url={node.frontmatter.path} key={node.frontmatter.path}>
                {node.excerpt}
              </Service>
            ))}
          </div>

          <div className="self-center mt-8">
            <Link to="/services">
              <StyledButton>View all services</StyledButton>
            </Link>
          </div>
        </div>

        <div className="flex flex-col mt-10 md:mt-16">
          <p className="mb-2 text-5xl text-gray-800 self-center">Trusted by</p>

          <div className="flex flex-wrap justify-center items-stretch -mx-2">
            <div className="w-full md:w-1/2 lg:w-1/4 mt-2">
              <div className="h-full m-2 p-4 flex flex-col items-center text-center">
                <img src={carphone} alt="carphone warehouse" style={{ maxHeight: `100px`, textAlign: `center` }} />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 mt-2">
              <div className="h-full m-2 p-4 flex flex-col items-center text-center">
                <img src={plusnet} alt="plusnet" style={{ maxHeight: `100px`, textAlign: `center` }} />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 mt-2">
              <div className="h-full m-2 p-4 flex flex-col items-center text-center">
                <img src={sknnt} alt="sknnt" style={{ maxHeight: `100px` }} />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 mt-2">
              <div className="h-full m-2 p-4 flex flex-col items-center text-center">
                <img src={bjss} alt="bjss" style={{ maxHeight: `100px` }} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-10 md:mt-16">
          <p className="mb-2 text-5xl text-gray-800 self-center">Get Ahead and Stay There</p>

          <div className="flex flex-wrap justify-center items-stretch -mx-2">
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="branding" src={branding} />
                </div>
                <p className="text-2xl w-full">Free Consultation</p>
                <p>New clients receive an obligation free consultation.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="processing" src={processing} />
                </div>
                <p className="text-2xl w-full">Latest Technologies</p>
                <p>Our development team is up to date with all the latest development trends and best practice.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="modeling" src={modeling} />
                </div>
                <p className="text-2xl w-full">Analytics as Standard</p>
                <p>
                  All of our sites have in-built metrics from the beginning using an industry standard analytics suite.
                  All part and parcel of the service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </HomePageContentLayout>
    </Layout>
  )
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/services/" }
        frontmatter: { featured: { eq: true }, draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
          }
          excerpt(pruneLength: 600, format: HTML)
        }
      }
    }
  }
`

export default Index
