import React from 'react'
import home from "../images/header-bg.jpg";

const HomePageContentLayout = ({ children }) => {

  return (
    <div>
      <div
        className="bg-center min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-contain bg-right-top bg-no-repeat flex flex-col items-center hidden md:flex"
        style={{ backgroundImage: `url(${home})` }}>
        <div className="w-full md:w-3/4 mt-20 md:mt-1 lg:mt-5 font-title self-center">
          <h1 className="text-2xl md:text-5xl md:text-white md:text-center leading-tight uppercase">
            Welcome to
            <br />
            DistortedThinking
            <br />
            Web Development Studio
          </h1>
          <p className="text-base md:text-white my-4 md:mt-8 md:text-center">
            Web applications and websites | Cloud deployments | <span className="break-normal">Mobile
            Applications</span> | Training | Consultation
          </p>
        </div>
        {children}
      </div>

      <div className="w-full mt-5 font-title self-center block md:hidden">
        <h1 className="text-2xl text-red-700 leading-tight uppercase">
          Welcome to
            <br />
            DistortedThinking
            <br />
            Web Development Studio
          </h1>
        <p className="text-base my-4">
          Web applications and websites | Cloud deployments | Mobile Applications | Training | Consultation
          </p>
        {children}
      </div>
    </div>
  );
}

export default HomePageContentLayout;
